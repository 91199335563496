.top_logo{
        width: 40px;
        height: 40px;
        margin-left: 6px;
        margin-top: 10px;
}

// .ScrollStyle
// {
//     float:left;
//    width:1000px;
//    overflow: auto;

// }
.css-yk16xz-control
{
    height: 33px;
}
.listshift
{
    margin-left: 16px;
}
.re_date_list{
    width: 327px;
    height: 32px;
    padding: 5px;
}
.re_date_pop {
    width: 404px;
    height: 32px;
    padding: 5px;
}
.re_date_form_complaint {
    width: 396px;
    height: 32px;
    padding: 5px;
}
.re_date_filter1{
        display: block;
        // width: 100%;
        height: calc(1.90625rem + 2px);
        padding: 0.25rem 0.7rem;
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.2rem;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .invalid-feedback11 {
   
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
    }
    
    
    .is-invalid1 {
        border-color: #dc3545;
        padding-right: 1.90625rem;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.125rem) center;
        background-size: calc(0.75em + 0.25rem) calc(0.75em + 0.25rem); }
        .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
          border-color: #dc3545;
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
    // .re_date11{
    //     width: 327px;
    //     height: 32px;
    //     padding: 5px;
    // }


    .form_datepicker{
        width: 424px;
        height: calc(1.90625rem + 2px);
        /* padding: 3px; */
        border: 1px solid #ced4da;
        border-radius: 0.2rem;
        height: calc(1.90625rem + 2px);
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .re_date1{
        width: 448px;
        height: calc(1.90625rem + 2px);
        /* padding: 3px; */
        border: 1px solid #ced4da;
        border-radius: 0.2rem;
        height: calc(1.90625rem + 2px);
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }


    .emp_dob_datepicker{
        width: 424px;
        height: calc(1.90625rem + 2px);
        /* padding: 3px; */
        border: 1px solid #ced4da;
        border-radius: 0.2rem;
        height: calc(1.90625rem + 2px);
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }


    .list_datepicker{
        width: 290px;
      
      }


    @media (min-width: 1200px) {
        .list_datepicker{
          width: 290px;
        
        }

        .re_date_form_complaint {
            width: 487px;
            height: 32px;
            padding: 5px;
        }


        .form_datepicker{
            width: 424px;
            height: calc(1.90625rem + 2px);
            /* padding: 3px; */
            border: 1px solid #ced4da;
            border-radius: 0.2rem;
            height: calc(1.90625rem + 2px);
            -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
        .re_date1{
            width: 448px;
            height: calc(1.90625rem + 2px);
            /* padding: 3px; */
            border: 1px solid #ced4da;
            border-radius: 0.2rem;
            height: calc(1.90625rem + 2px);
            -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }


        .emp_dob_datepicker{
            width: 424px;
            height: calc(1.90625rem + 2px);
            /* padding: 3px; */
            border: 1px solid #ced4da;
            border-radius: 0.2rem;
            height: calc(1.90625rem + 2px);
            -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }








        
   } 


   @media (min-width: 1440px) {
    .list_datepicker{
      width: 512px;
    
    }
    .re_date_form_complaint {
        width: 487px;
        height: 32px;
        padding: 5px;
    }
    .form_datepicker{
        width: 791px;
        height: calc(1.90625rem + 2px);
        /* padding: 3px; */
        border: 1px solid #ced4da;
        border-radius: 0.2rem;
        height: calc(1.90625rem + 2px);
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .re_date1{
        width: 791px;
        height: calc(1.90625rem + 2px);
        /* padding: 3px; */
        border: 1px solid #ced4da;
        border-radius: 0.2rem;
        height: calc(1.90625rem + 2px);
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
} 













    .re_date_form{
        width: 505px;
        height: 32px;
        padding: 5px;
    }
.badge-error {
        color: #fff;
        background-color: #f11212;
        border-radius: 50%;
    }

    .indicatorno {
        background: #fd7e14;
       // box-shadow: 0 0 2rem 0 rgb(0 0 0 / 5%);
        border-radius: 50%;
        display: block;
        height: 9px;
        width: 9px;
        position: absolute;
        top: 18px;
        right: 0;
        -webkit-transition: top .1s ease-out;
        transition: top .1s ease-out;
    }

 #google_translate_elementnav
{
        // margin-top: -42px;
        // /* height: 30px; */
        // margin-left: 69%;
//         margin-bottom: -20px;
//     margin-left: 653px;
// margin-left: 638px;
    margin-top: 19px;
}


.button_change_dashboard
{
        height: 34px;
    width: 161px;
    margin-top: 15px;
    /* margin-left: 77px; */
    margin-right: 21px;
}
.ScrollStyle_sidebar11111
{
    //float: left;
    //height: 600px;
    //width: 246px;
    // overflow-y: auto;
    // overflow-x: hidden;
    height: 500px; 
overflow-y : auto;
}


.ScrollStyle_sidebar
{
        float: left;
        //height: 600px;
        width: 246px;
        overflow-y: auto;
        overflow-x: hidden;
}
#vertical_scroll_sidebar::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgb(152,152,152);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#vertical_scroll_sidebar::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}
#vertical_scroll_sidebar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgb(152,152,152);
	background-color: #555;
}
.copyright{
        margin-left: 19px;;
}

#pro_logo{
        margin-top: 19px;;
}

.indicatormsg {
    background: #f11212;
    border-radius: 50%;
    display: block;
    height: 9px;
    width: 9px;
    top: 18px;
    right: 0;
    transition: top .1s ease-out;
}
.custom-language-list {
    margin-top: 20px;
    padding: 3px 10px;
  }
