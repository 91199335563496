.Custom_Cancel{
    background-color: #78ea78;
    color: black;
}
.search{
    width: 178px;
    margin-left: 125px;
}
.search_title{
    margin-left: 125px;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  margin-right: auto;
  margin-left: auto; }
#google_translate_element_org_add{
   margin-top: -24px;
height: 30px;
margin-left: 80%;
}
#google_translate_element_org{
   margin-top: -24px;
height: 30px;
margin-left: 80%;
}
.e_div{
    margin-top:-96px
}
.s_div{
    margin-top: 60px;
}

.search_org{
    width: 178px;
    margin-left: 125px;
}
.search_org_label{
      margin-top: 5px;
      margin-left: 134px;
}
.f_class_org{
    margin-top: 40px;
    margin-bottom: -213px;
    }
